import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import {
  PhantomWalletAdapter,
  SolletWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
  TorusWalletAdapter,
  LedgerWalletAdapter,
  SlopeWalletAdapter,
  GlowWalletAdapter,
  CloverWalletAdapter,
  SolletExtensionWalletAdapter,
  Coin98WalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { __DEBUG__ } from "../dev";

const network = __DEBUG__
  ? WalletAdapterNetwork.Devnet
  : WalletAdapterNetwork.Mainnet;

export const walletOptions = {
  wallets: [
    new PhantomWalletAdapter(),
    new GlowWalletAdapter({ network }),
    new SlopeWalletAdapter({ network }),
    new SolletWalletAdapter({ network }),
    new SolflareWalletAdapter({ network }),
    new MathWalletAdapter({ network }),
    new TorusWalletAdapter({ network }),
    new LedgerWalletAdapter(),
    new CloverWalletAdapter({ network }),
    new SolletExtensionWalletAdapter({ network }),
    new Coin98WalletAdapter({ network }),
  ],
  autoConnect: true
};

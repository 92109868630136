import { timeline, spring } from "motion"


export function jiggleElement(selector) {
  return timeline([
    [selector, { scale: 1.2 },],
    [selector, {
      rotateZ: 10
    }],
    [selector, {
      rotateZ: -10
    }],
    // [selector, {
    //   rotateZ: 10
    // }],
    // [selector, {
    //   rotateZ: -10
    // }],
    [selector, {
      rotateZ: 10
    }],
    [selector, {
      rotateZ: 0,
      scale: 1
    }],
  ], {
    duration: 1,
    defaultOptions: {
      // easing: spring()
    },
  })
}
import { toPascalCase } from "~/utils/string";
import BottomPanel from "~/components/ui/bottom-panel.vue";
import EditButton from "~/components/ui/edit-button.vue";
import MirrorCard from "~/components/ui/mirror-card.vue";
import RctIcon from "~/components/ui/rct-icon.vue";
import HistoryEditModal from "~/views/chat/history-edit-modal.vue";
import Logo from "~/components/widgets/logo.vue";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

export default {
  install(app) {
    app.component(toPascalCase("HistoryEditModal"), HistoryEditModal);
    app.component(toPascalCase("BottomPanel"), BottomPanel);
    app.component(toPascalCase("EditButton"), EditButton);
    app.component(toPascalCase("MirrorCard"), MirrorCard);
    app.component(toPascalCase("RctIcon"), RctIcon);
    app.component(toPascalCase("Logo"), Logo);

    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component);
    }
  },
};

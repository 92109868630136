import { onMounted, readonly, ref } from "vue";
import { useWallet } from "solana-wallets-vue";
import * as web3 from "@solana/web3.js";
import { __DEBUG__ } from "~/utils/dev";
// import { solana } from "~/services/apis/solana";
import { nftAPI } from "~/services/apis";
import { jiggleElement } from "~/views/chat/jiggle-element";

if (typeof window !== "undefined") {
  window.$web3 = web3;
}

const { clusterApiUrl, Connection, PublicKey } = web3;

let isInitialized = false;
/** @type {import('vue').Ref<import('@mirrorworld/library.upgrade').LevelUpgrade>} */
const solanaUpgrade = ref(null);

export const MIRRORWORLD_SOLANA_RPC =
  "https://rpc.mirrorwrld.io/572a09728de3d9f5b8a99b91d799d87234ef1c19/";

export function useSolanaUpgrade() {
  const { connected, signTransaction, wallet } = useWallet();
  const connection = new Connection(
    __DEBUG__ ? clusterApiUrl("devnet") : MIRRORWORLD_SOLANA_RPC
  );

  async function signLevelUpTransaction(transaction) {
    if (!connected.value) {
      const connectSolanaButton = document.querySelector(".solana-button");
      if (connectSolanaButton) {
        await jiggleElement(connectSolanaButton);
      }
      throw new Error("Please connect your wallet before upgrading");
    }
    console.debug("Upgrade transaction: ", transaction);
    console.debug("Requesting user signature ...");
    const serializedTransaction = Buffer.from(transaction, "base64");
    const txt = web3.Transaction.from(serializedTransaction);
    console.debug("Constructed transaction from authority ...");
    const signedUpgradeTransaction = await signTransaction.value(txt);
    console.debug("Signed upgrade transaction ...");
    console.debug("Sending txt ...");
    const signature = await connection.sendRawTransaction(
      signedUpgradeTransaction.serialize()
    );
    const result = await connection.confirmTransaction(signature, "finalized");
    console.debug("Successfully sent txt ...", result);
    console.log("result", result);
    return { result, signature };
  }

  async function initalize() {
    const { LevelUpgrade, LEVEL_UPGRADE_PROGRAM_ID } = await import(
      /** webpackChunkName: "MirrorSolanaUpgradeSDK" */ "@mirrorworld/library.upgrade"
    );
    solanaUpgrade.value = new LevelUpgrade(
      LEVEL_UPGRADE_PROGRAM_ID,
      connection,
      // @ts-ignore
      wallet.value
    );
    isInitialized = true;
  }

  onMounted(async () => {
    if (!solanaUpgrade.value || !isInitialized) {
      await initalize();
      // @ts-ignore
      window.$solanaUpgrade = solanaUpgrade.value;
    }
  });

  return {
    solanaUpgrade: readonly(solanaUpgrade),
    signLevelUpTransaction,
  };
}

export function isSolanaAddress(sol_address) {
  try {
    const publicKey = new PublicKey(sol_address);
    if (PublicKey.isOnCurve(publicKey.toBytes())) return true;
  } catch (e) {
    return false;
  }
  return false;
}

export const isSolanaBot = (bot) => isSolanaAddress(bot.owner_address);

export const getBotSolanaNFT = async (bot) => {
  return nftAPI.getSolanaMintInfo({
    token_id: String(bot.bot_id),
  });
};

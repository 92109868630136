import axios from "axios";
import { __DEBUG__ } from "~/utils/dev";

import Cookies from "js-cookie";

export const sso = axios.create({
  baseURL: __DEBUG__
    ? "https://mirrorworld-sso-staging.mirrorworld.fun"
    : "https://mirrorworld-sso.mirrorworld.fun",
});

sso.interceptors.request.use((config) => {
  const COOKIE_SSO_TOKEN = "sso-t";
  const token = Cookies.get(COOKIE_SSO_TOKEN);
  const BearerToken = `Bearer ${token}`
  return {
    ...config,
    headers: {
      ...config.headers,
      ...(token && { Authorization: BearerToken  }),
    },
  };
});
